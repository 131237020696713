<template>
  <div>
    <BarChart
      v-if="data.length"
      :data="data"
      :labels="labels"
      :formatter="formatter"
      @click-dots="managing = true"
    >
      <div slot="title">
        <div slot="title">
          {{ currentOptions.nicename }}
        </div>
        <small slot="subtitle">
          <span>From {{ startDate }} to {{ endDate }}, {{ results ? results.records.length : 0 }} records (Per {{ displayDuration }} {{ currentOptions.query.interval_unit }}).</span>
        </small>
        <v-chip v-if="currentOptions.filter.nature">
          {{ currentOptions.filter.nature }}
        </v-chip>
        <v-chip v-if="currentMerchant">
          {{ t(currentMerchant.name) }}
        </v-chip>
        <v-chip v-if="currentChannel">
          {{ t(currentChannel.name) }}
        </v-chip>
      </div>
    </BarChart>

    <ChartOptionAside
      v-if="managing"
      :active="true"
      :resource="currentOptions"
      @aside="bool => { if (!bool) { managing = false } }"
      @submit="handleOptionUpdate"
      @remove="$emit('remove')"
    />
  </div>
</template>

<script>
import { useCurrency } from '@/composables'
import channelStore from '@/modules/channel/store'
import merchantStore from '@/modules/merchant/store'
import { t } from '@/plugins/i18n'
import { computed, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import useAggregation from '../useAggregation'
import BarChart from './BarChart.vue'
import ChartOptionAside from './ChartOptionAside.vue'

const defaultOptions = {
  route: 'order-items',
  formatter: 'default',
  nicename: 'Default Title',
  sort: 50,
  query: {
    action: 'count', // sum, count
    action_value: null, // null or sum field name
    interval_count: 7,
    interval_duration: 1,
    interval_unit: 'day',
    start_date: null, // empty = last N days
  },
  filter: {
    merchant_id: null,
    channel_id: null,
  },
}

export default {
  components: { BarChart, ChartOptionAside },

  props: {
    options: {
      type: Object,
      default: () => defaultOptions,
    },
  },

  setup(props, { emit }) {
    const managing = ref(false)
    const results = ref(null)
    const currentOptions = computed(() => {
      const options = {
        ...defaultOptions,
        ...props.options,
        func: {
          ...defaultOptions.func,
          ...(props.options.func || {}),
        },
        query: {
          ...defaultOptions.query,
          ...(props.options.query || {}),
        },
        filter: {
          ...defaultOptions.filter,
          ...(props.options.filter || {}),
        },
      }

      return options
    })

    const currentMerchant = computed(() => merchantStore.state.all.find(m => m.id === currentOptions.value.filter.merchant_id))

    const currentChannel = computed(() => channelStore.state.channels.find(ch => ch.id === currentOptions.value.filter.channel_id))

    const parsedStartDate = computed(() => {
      const { interval_count, interval_unit, start_date } = currentOptions.value.query

      return start_date || dayjs().subtract(interval_count, interval_unit).startOf('day').format()
    })

    const aggregation = useAggregation()

    aggregation.handle({
      // route: currentOptions.value.route,
      // query: {
      //   action: 'count',
      //   start_date: dayjs().subtract(5, 'day').startOf('day').format(),
      //   filter: {},
      // },
      ...currentOptions.value,
      query: {
        ...currentOptions.value.query,
        start_date: parsedStartDate.value,
      },

    }).then(res => {
      results.value = res.data.data
    })

    const d = date => dayjs(date).format('YYYY-MM-DD')
    const sd = date => dayjs(date).format('MM-DD')

    const data = computed(() => (results.value ? results.value.records.map(r => r.value) : []))

    // const labels = computed(() => (results.value ? results.value.records.map(r => `${d(r.start_date)} - ${d(r.end_date)}`) : []))
    const labels = computed(() => (results.value ? results.value.records.map(r => `${sd(r.start_date)}`) : []))

    const { baseCurrency } = useCurrency()
    const formats = {
      default: value => value,
      dollar: value => `${baseCurrency.value.long_symbol}${value}`,
    }

    const formatter = computed(() => formats[props.options.formatter] || formats.default)

    const startDate = computed(() => d(results ? results.value.start_date : null))

    const endDate = computed(() => (results ? d(results.value.end_date) : null))

    const displayDuration = computed(() => {
      const duration = currentOptions.value.query.interval_duration

      return duration > 1 ? duration : ''
    })

    const handleOptionUpdate = form => {
      console.log('form', form)
      managing.value = false
      emit('submit', form)
    }

    return {
      t,
      managing,
      currentOptions,
      currentMerchant,
      currentChannel,

      results,
      data,
      labels,

      formats,
      formatter,

      startDate,
      endDate,
      displayDuration,

      handleOptionUpdate,
    }
  },
}
</script>
